import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import ukFlag from '../assets/ukFlag.png';
import trFlag from '../assets/trFlag.png';
import '../styles/header.css';

const Header = ({ switchLanguage, language }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLanguageSwitch = () => {
    switchLanguage(language === 'tr' ? 'en' : 'tr');
  };

  return (
    <header className={`header-wrapper ${menuOpen ? 'expanded' : ''}`}>
      <div className="header-left">
        <img src={logo} alt="Toyo Logo" className="logo" />
        <h1 className="title">TOYO SMART HOME & AUTOMATION</h1>
        <div className="hamburger" onClick={toggleMenu}>
          ☰
        </div>
      </div>
      <nav className={`nav ${menuOpen ? 'open' : ''}`}>
        <Link to="/" className="nav-link" onClick={() => setMenuOpen(false)}>{language === 'tr' ? "Anasayfa" : "Home"}</Link>
        <a href="https://wa.me/message/KK5BC4O7JIRQK1" target="_blank" rel="noopener noreferrer" className="external-link" onClick={() => setMenuOpen(false)}>
          {language === 'tr' ? "İletişim" : "Contact"}
        </a>
        <button onClick={handleLanguageSwitch} className="language-switcher">
          <img src={language === 'tr' ? ukFlag : trFlag} alt="flag" className="flag-icon" />
          {language === 'tr' ? 'EN' : 'TR'}
        </button>
      </nav>
    </header>
  );
};

export default Header;
