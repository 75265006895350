import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import Home from './components/home';
import StructuredData from './structuredData';
import './index.css';
import { Helmet } from 'react-helmet';

const App = () => {
  const [language, setLanguage] = useState('tr');

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <Router>
      <Helmet>
        <title>{language === 'tr' ? 'Toyo Mühendislik' : 'Toyo Engineering'}</title>
        <meta name="description" content={language === 'tr' ? 'Toyo Mühendislik KKTC - Akıllı Ev ve Otomasyon Çözümleri' : 'Toyo Mühendislik TRNC - Smart Home & Automation Solutions'} />
        <link rel="canonical" href="https://toyomuhendislik.com/" />
        <link rel="alternate" href="https://toyomuhendislik.com/" hreflang="tr" />
        <link rel="alternate" href="https://toyomuhendislik.com/" hreflang="en" />
      </Helmet>
      <StructuredData />
      <div className="container">
      <Header switchLanguage={switchLanguage} language={language} />
        <Routes>
          <Route exact path="/" element={<Home language={language} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
