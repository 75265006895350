import React from 'react';
import bannerImageEN from '../assets/bannerEN2.jpg';
import bannerImageTR from '../assets/bannerTR.jpg';
import smartHomeImage from '../assets/smartHome.jpg';
import proje from '../assets/proje.jpg';
import securitySystem from '../assets/securitySystem.jpg';
import irrigation from '../assets/irrigation.jpg';
import software from '../assets/software.jpg';
import factory from '../assets/factory.jpeg';
import whatsappIcon from '../assets/whatsapp.png';
import '../styles/home.css';

const descriptions = {
  smartHomeEN: 'Experience the future of living with our smart building systems, designed and implemented for both new projects and existing buildings. We bring the power of automation to your fingertips.',
  
  smartHomeTR: 'Yeni projeler veya mevcut binalar için tasarlayıp uyguladığımız akıllı bina sistemleriyle otomasyonun gücünü parmaklarınızın ucuna getiriyoruz.',

  securityEN: 'Keep your property secure with our comprehensive security solutions, including CCTV systems, burglar alarms, and access control. We ensure your building is protected around the clock.',
  
  securityTR: 'CCTV sistemleri, hırsız alarmları ve erişim kontrolü gibi kapsamlı çözümlerimizle binanızı güvende tutun.',

  buildingEN: 'Enhance your projects with our expert electrical services. Whether residential or commercial, we design modern electrical systems tailored to your needs.',
  
  buildingTR: 'Konut veya ticari projenizin elektrik sistemlerini yeni ve modern teknolojilere göre sizin için tasarlayalım.',

  irrigationEN: 'Achieve a vibrant garden effortlessly with our innovative irrigation and garden automation solutions. Our smart watering systems adapt to weather, soil moisture, and plant needs, keeping your garden flourishing year-round.',
  
  irrigationTR: 'Yenilikçi sulama ve bahçe otomasyon çözümlerimizle hizmetinizdeyiz. Hava koşulları, toprak nemi ve bitki ihtiyaçlarına göre uyum sağlayan akıllı sulama sistemlerimizle, bahçeniz yıl boyunca canlı kalsın.',

  factoryEN: 'Boost your production efficiency with our tailored industrial solutions, from robotic systems to conveyor systems and custom software. We meet your unique industrial needs with cutting-edge technology.',
  
  factoryTR: 'Robotik sistemlerden konveyör sistemlerine, özel yazılım çözümlerine kadar, endüstriyel ihtiyaçlarınıza özel hizmetler sunuyoruz.',

  softwareEN: 'Unlock your business potential with our bespoke software and AI solutions. We turn your ideas into reality with customized applications that enhance operations and decision-making.',
  
  softwareTR: 'Özel yazılım ve yapay zeka çözümlerimizle işletmenizin tam potansiyelini ortaya çıkartıp fikirlerinizi gerçeğe dönüştürün.'
};

const Home = ({ language }) => (
  <div className="home-wrapper">
    <div className="banner" style={{ backgroundImage: `url(${language === 'tr' ? bannerImageTR : bannerImageEN})` }} />
    <section className="services-section">
      <h2>{language === 'tr' ? 'Hizmetlerimiz' : 'Our Services'}</h2>
      <div className="services">
        <div className="service">
          <h4>{language === 'tr' ? 'Akıllı Ev/Bina' : 'Smart Home/Building'}</h4>
          <img src={smartHomeImage} alt="Akıllı Ev ve Akıllı Bina KKTC" style={{ width: '95%', height: 'auto' }} />
          <p>{language === 'tr' ? descriptions.smartHomeTR : descriptions.smartHomeEN}</p>
        </div>
        <div className="service">
          <h4>{language === 'tr' ? 'Elektrik Projesi ve Kontrolü' : 'Building Services (Electrical)'}</h4>
          <img src={proje} alt={language === 'tr' ? 'Elektrik Projesi ve Kontrolü KKTC' : 'Building Services (Electrical) TRNC'} style={{ width: '95%', height: 'auto' }} />
          <p>{language === 'tr' ? descriptions.buildingTR : descriptions.buildingEN}</p>
        </div>
        <div className="service">
          <h4>{language === 'tr' ? 'Güvenlik Sistemleri' : 'Security Systems'}</h4>
          <img src={securitySystem} alt={language === 'tr' ? 'Güvenlik Sistemleri KKTC' : 'Security Systems TRNC'} style={{ width: '95%', height: 'auto' }} />
          <p>{language === 'tr' ? descriptions.securityTR : descriptions.securityEN}</p>
        </div>
      </div>

      <div className="services">
        <div className="service">
          <h4>{language === 'tr' ? 'Tarım ve Bahçe Otomasyonu' : 'Farm and Irrigation Automation'}</h4>
          <img src={irrigation} alt={language === 'tr' ? 'Tarım ve Bahçe Otomasyonu KKTC' : 'Farm and Irrigation Automation TRNC'} style={{ width: '95%', height: 'auto' }} />
          <p>{language === 'tr' ? descriptions.irrigationTR : descriptions.irrigationEN}</p>
        </div>
        <div className="service">
          <h4>{language === 'tr' ? 'Yazılım ve Yapay Zeka' : 'Software and Artificial Intelligence'}</h4>
          <img src={software} alt={language === 'tr' ? 'Yazılım ve Yapay Zeka KKTC' : 'Software and Artificial Intelligence TRNC'} style={{ width: '95%', height: 'auto' }} />
          <p>{language === 'tr' ? descriptions.softwareTR : descriptions.softwareEN}</p>
        </div>
        <div className="service">
          <h4>{language === 'tr' ? 'Fabrika Otomasyonu' : 'Factory Automation'}</h4>
          <img src={factory} alt={language === 'tr' ? 'Fabrika Otomasyonu KKTC' : 'Factory Automation TRNC'} style={{ width: '95%', height: 'auto' }} />
          <p>{language === 'tr' ? descriptions.factoryTR : descriptions.factoryEN}</p>
        </div>
      </div>
    </section>
    
    <section className="contact-section">
      <h3>{language === 'tr' ? 'Bir sonraki projenizi görüşelim' : "Let's discuss your next project"}</h3>
      <a href="https://wa.me/message/KK5BC4O7JIRQK1" target="_blank" rel="noopener noreferrer" className="whatsapp-button">
        <img src={whatsappIcon} alt="WhatsApp" />
        WhatsApp
      </a>
      <h4>{language === 'tr' ? 'İletişim Numarası' : 'Contact Phone Number'}</h4>
      <p>{language === 'tr' ? '+90 542 883 09 48' : '+90 542 883 09 48'}</p>
    </section>

    <a href="https://wa.me/message/KK5BC4O7JIRQK1" target="_blank" rel="noopener noreferrer" className="whatsapp-floating-button">
      <img src={whatsappIcon} alt="WhatsApp" />
    </a>
    
  </div>
);

export default Home;
