import React from 'react';
import { Helmet } from 'react-helmet';

const StructuredData = () => {
  const schemaDataTR = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "name": "Toyo Mühendislik",
    "image": "https://www.toyomuhendislik.com/logo.png",  // Replace with your logo URL
    "@id": "https://www.toyomuhendislik.com/",
    "url": "https://www.toyomuhendislik.com/",
    "telephone": "+90 542 883 09 48",  // Replace with your phone number
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Uçurtma Sokak",  // Replace with your street address
      "addressLocality": "Lefkoşa",  // Replace with your city
      "addressRegion": "KKTC",  // Replace with your region/state
      "postalCode": "99150",  // Replace with your postal code
      "addressCountry": "TR"  // Replace with your country code
    },
    "description": "Toyo Mühendislik - KKTC'de akıllı ev sistemleri ve otomasyon çözümleri.",
    "sameAs": [
      "https://www.instagram.com/toyomuhendislik"
    ]
  };

  const schemaDataEN = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "name": "Toyo Engineering",
    "image": "https://www.toyomuhendislik.com/logo.png",  // Replace with your logo URL
    "@id": "https://www.toyomuhendislik.com/en",
    "url": "https://www.toyomuhendislik.com/en",
    "telephone": "+90 123 456 78 90",  // Replace with your phone number
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Ucurtma Street",  // Replace with your street address
      "addressLocality": "Nicosia",  // Replace with your city
      "addressRegion": "TRNC",  // Replace with your region/state
      "postalCode": "99150",  // Replace with your postal code
      "addressCountry": "TR"  // Replace with your country code
    },
    "description": "Toyo Engineering - Smart home systems and automation solutions in TRNC.",
    "sameAs": [
      "https://www.instagram.com/toyomuhendislik"
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaDataTR)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(schemaDataEN)}
      </script>
    </Helmet>
  );
};

export default StructuredData;
